import React, { useContext } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import happy_birthday_gift from "../images/svg/happy_birthday_gift.svg"

import { StoreContext } from "../context/StoreContext"

const ThankYouPage = () => {
  const isBrowser = typeof window !== "undefined"

  const getUrlVars = () => {
    let vars = {}
    if (isBrowser) {
      let parts = window.location.href.replace(
        /[?&]+([^=&]+)=([^&]*)/gi,
        function(m, key, value) {
          vars[key] = value
        }
      )
    }
    return vars
  }

  const runPixels = () => {
    const didPixelRun = isBrowser
      ? localStorage.getItem(`pixels_#WAG${getUrlVars().order_number}`)
      : null

    if (!didPixelRun && isBrowser) {
      // Only call pixel once
      if (process.env.NODE_ENV === "production") {
        if (window.gtag) {
          window.gtag("event", "conversion", {
            send_to: "AW-699771945/n6dJCMKvsLEBEKnY1s0C",
            value: parseFloat(getUrlVars().total),
            currency: "USD",
            transaction_id: `#WAG${getUrlVars().order_number}`,
          })
        }

        if (window.fbq) {
          window.fbq("track", "Purchase", {
            value: parseFloat(getUrlVars().total),
            currency: "USD",
          })
        }

        if (window.snaptr) {
          window.snaptr("track", "PAGE_VIEW")
          window.snaptr("track", "PURCHASE", {
            price: parseFloat(getUrlVars().total),
            currency: "USD",
            transaction_id: `#WAG${getUrlVars().order_number}`,
          })
        }

        let someDate = new Date()
        let numberOfDaysToAdd = 14
        someDate.setDate(someDate.getDate() + numberOfDaysToAdd)

        let dd = someDate.getDate()
        let mm = someDate.getMonth() + 1
        let y = someDate.getFullYear()

        let formattedDate = y + "-" + mm + "-" + dd

        // Product reviews
        window.renderOptIn = function() {
          window.gapi.load("surveyoptin", function() {
            window.gapi.surveyoptin.render({
              merchant_id: "144040947",
              order_id: `#WAG${getUrlVars().order_number}`,
              email: getUrlVars().email,
              delivery_country: getUrlVars().country,
              estimated_delivery_date: formattedDate,
              opt_in_style: "BOTTOM_RIGHT_DIALOG",
            })
          })
        }

        window.renderOptIn()

        localStorage.setItem(`pixels_#WAG${getUrlVars().order_number}`, true)
      }
    }
  }

  runPixels()

  return (
    <Layout>
      <SEO title="Your order is confirmed!" />
      <section className="section">
        <div className="container has-text-centered">
          <h1 className="is-size-1 has-text-centered">
            Your order is confirmed!
          </h1>

          <hr />

          <p className="is-size-6">{`Order #WAG${
            getUrlVars().order_number
          }`}</p>
          <p className="is-size-4 has-text-weight-bold">{`Thank You, ${
            getUrlVars().customer
          }!`}</p>
          <p className="is-size-6">
            You’ll receive an email when your order is ready.
          </p>
          <p className="is-size-6">
            If you have any questions feel free to email us at{" "}
            <a href="mailto:info@waggtopia.com">info@waggtopia.com</a>
          </p>

          <figure style={{ marginTop: 30 }}>
            <img width={300} height={300} src={happy_birthday_gift} />
          </figure>

          <p style={{ marginTop: 20 }}>Do you want 25% off your next order?</p>
          <p>
            Use discount code "<b style={{ color: "#02b0f1" }}>25OFF</b>" at
            checkout.{" "}
          </p>
          <p>
            Valid <b>TODAY</b> only!
          </p>
        </div>

        <hr />

        <div className="columns is-centered has-text-centered">
          <div className="column is-4">
            <Link to="/product/personalized-pet-pillows">
              <button className="button is-primary is-large">
                THROW PILLOWS
              </button>
            </Link>
          </div>
          <div className="column is-4">
            <Link to="/product/personalized-pet-sequin-pillows">
              <button className="button is-primary is-large">
                SEQUIN PILLOWS
              </button>
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ThankYouPage
